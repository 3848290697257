import axios from 'axios'
import Interceptors from '../helpers/interceptors'
import AuthStorageProvider from './AuthStorageProvider'

class APIProvider {
  constructor(apiURL) {
    this.apiURL = apiURL
    
    this.client = axios.create({
      baseURL: apiURL,
      withCredentials: false,
      timeout: Infinity,
    })
    
    const accessToken = AuthStorageProvider.getAccessToken()
    this.setAuthorizationHeader(accessToken)
    this.mountAuthInterceptor(this.client)
  }
  
  mountAuthInterceptor() {
    Interceptors.mountAuthInterceptor(this.client)
  }
  
  unmountAuthInterceptor() {
    Interceptors.unmountAuthInterceptor(this.client)
  }
  
  setAuthorizationHeader(token) {
    if (token) this.client.defaults.headers.common.Authorization = `Bearer ${token}`
  }
  
  removeAuthorizationHeader() {
    delete this.client.defaults.headers.common.Authorization
  }
  
  async confirmAccount(token) {
    const response = await  this.client.post('/auth/confirm', {token})
    AuthStorageProvider.saveAccessToken(response.data.token)
    // AuthStorageProvider.saveRefreshToken(response.data.refresh_token)
  
    this.setAuthorizationHeader(response.data.token)
    
    return response
  }
  
  async login({email, password}) {
    const response = await this.client.post('/auth/login', {email, password})
  
    AuthStorageProvider.saveAccessToken(response.data.token)
    // AuthStorageProvider.saveRefreshToken(response.data.refresh_token)
  
    this.setAuthorizationHeader(response.data.token)
    
    return response
  }
  
  signup({email, password}) {
    return this.client.post('/auth/register', {email, password})
  }
  
  async getProfile() {
    // await new Promise(resolve => setTimeout(resolve, 5000))
    return this.client.get('/profile/me')
  }
  
  forgotPassword({email}) {
    return this.client.post('/auth/reset-password', {email})
  }
  
  forgotPasswordConfirm({token, password}) {
    return this.client.post('/auth/reset-password/confirm', {token, password})
  }
  
  changeProfilePassword({ oldPassword, newPassword }) {
    return this.client.post('/profile/change-password', {oldPassword, newPassword})
  }
  
  paySubscription({gateway, plan}) {
    return this.client.post('/subscriptions/pay', {
      gateway,
      plan,
    })
  }
  
  paySubscriptionFinalize({gateway, data, status}) {
    return this.client.post('/subscriptions/pay-finalize', {
      gateway,
      data,
      status
    })
  }

  cancelSubscription(id) {
    return this.client.delete(`/subscriptions/${id}`)
  }

  async logout() {
    AuthStorageProvider.removeAccessToken()
    AuthStorageProvider.removeRefreshToken()
    // this.unmountAuthInterceptor()
    this.removeAuthorizationHeader()
  }
  
  createNote({
               encryptedMessage,
               messageLength,
               files,
               burnDate,
               encryptionScheme,
             }, {onProgress}) {
    return this.client.post('/note', {
      encryptedMessage,
      messageLength,
      files,
      burnDate,
      encryptionScheme
    }, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        if (totalLength !== null) {
          const progressData = Math.round( (progressEvent.loaded * 100) / totalLength );
          console.log('progress', progressData)
          onProgress(progressData)
        }
      }
    })
  }
  
  getNote(id) {
    return this.client.get(`/note/${id}`)
  }
  
  getNoteStatus(id) {
    return this.client.get(`/note/${id}/status`)
  }

  getGoogleAuthURL() {
    return `${process.env.VUE_APP_BASE_URL || process.env.APP_FRONTEND_URL || window.location.origin}/api/auth/google`
  }

  sendGoogleAuthCode(code) {
    return this.client.get(`/auth/google/callback`, {
      params: { code }
    })
  }

  async setGoogleAuthToken({token}) {
    AuthStorageProvider.saveAccessToken(token)
    // AuthStorageProvider.saveRefreshToken(response.data.refresh_token)
  
    this.setAuthorizationHeader(token)
  }

  sendEvent({e, uid}) {
    return this.client.post(`/amp/e`, {
      e,
      uid
    })
  }
}
const BACKEND_API_URL = process.env.VUE_APP_BASE_URL ? `${process.env.VUE_APP_BASE_URL}/api` : '/api'
export default new APIProvider(BACKEND_API_URL)
