import {
  SET_MODEL,
  FETCH_REQUEST,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_ERROR,
  SHOW_TOAST,
  SET_PROPERTY
} from '../mutations/types'

export const MODULE_NAME = 'subscriptions'
export const CLEAR_PROFILE = `${MODULE_NAME}_clearProfile`
export const SEND_PAYMENT_STATUS = `${MODULE_NAME}_sendPayStatus`
export const PAY_SUBSCRIPTION = `${MODULE_NAME}_paySubscription`

const getDefaultRequestState = (data = null) => ({
  loading: false,
  data,
  error: null
})

export default {
  state: {
    paySubscription: getDefaultRequestState(null),
    sendPayStatus: getDefaultRequestState(null),
    variables: {
      pubKey: process.env.NODE_ENV === 'production' ? 'pk_live_51ILpgLIgoARgSrcMpS5aVIFjgZZmIq2ltUBjBYZRRyXelu5Q4Ih4zKHFWoHZXqQ26V2qpmKD3rVNwj9WJIl2gnU100uWCSR1em' : 'pk_test_51ILpgLIgoARgSrcMrx1a6oue7OlAAAyqQZHCkvAX3zppTvD35c8HbtOUGRfFTfI2r1sg2C5N7fKcIcPdyBwUNUfg00nsHAIWeq'
    },
  },
  actions: {
    [PAY_SUBSCRIPTION]({ commit, getters }, {plan}) {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'paySubscription' })
      
      return getters.apiService
        .paySubscription({gateway: 'stripe', plan})
        .then(({ data }) => {
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'paySubscription', data })
          return data
        })
        .catch((e) => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'paySubscription',
            error: e.response && e.response.data && e.response.data.message || e.message || 'Sending payment status'
          })
        })
    },
    [SEND_PAYMENT_STATUS]({ commit, getters }, { gateway, data, status }) {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'sendPayStatus' })
      
      return getters.apiService
        .paySubscriptionFinalize({gateway, data, status})
        .then(({ data }) => {
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'sendPayStatus', data })
          return data
        })
        .catch((e) => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'sendPayStatus',
            error: e.response && e.response.data && e.response.data.message || e.message || 'Sending payment status'
          })
        })
    },
  }
}
