import auth from './auth'
import profile from './profile'
import subscriptions from './subscriptions'
// import signup from './signup'

export default {
  auth,
  // signup,
  profile,
  subscriptions
}
