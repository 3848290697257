import Vue from 'vue'
import VueRouter from 'vue-router'
import notFound from './middleware/notFound'
import auth from './middleware/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/layouts/Default.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/pages/Home.vue'),
      },
      {
        path: '/note/note-created',
        name: 'note-created',
        component: () => import('../views/pages/NoteCreated.vue'),
      },
      {
        path: '/note/:noteId',
        name: 'get-note-old',
        component: () => import('../views/pages/Note.vue'),
      },
      {
        path: '/:noteId',
        name: 'get-note',
        component: () => import('../views/pages/Note.vue'),
      },

      {
        path: '/page/note-hidden',
        name: 'get-note-hidden',
        component: () => import('../views/pages/Note.vue'),
      },

      {
        path: '/page/about-us',
        name: 'about-us',
        component: () => import('../views/pages/AboutUs.vue'),
      },

      {
        path: '/page/sign-in',
        name: 'sign-in',
        component: () => import('../views/pages/SignIn.vue'),
      },
      {
        path: '/page/sign-in/google/success',
        name: 'sign-in-google-success',
        component: () => import('../views/pages/SignInGoogle.vue'),
      },

      {
        path: '/page/sign-up',
        name: 'sign-up',
        component: () => import('../views/pages/SignUp.vue'),
      },
      {
        path: '/page/sign-up/success',
        name: 'sign-up-success',
        component: () => import('../views/pages/SignupSuccess.vue'),
      },
      {
        path: '/page/confirm-account',
        name: 'confirm-account',
        component: () => import('../views/pages/ConfirmAccount.vue'),
      },
      {
        path: '/page/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/pages/ForgotPassword.vue'),
      },

      {
        path: '/page/forgot-password/confirm',
        name: 'forgot-password-confirm',
        component: () => import('../views/pages/ForgotPasswordConfirm.vue'),
      },

      {
        path: '/page/subscriptions',
        name: 'choose-subscription',
        component: () => import('../views/pages/SubscriptionChoose.vue'),
        meta: {
          middleware: [auth]
        },
      },

      {
        path: '/page/subscriptions/finalize',
        name: 'finalize-subscription',
        component: () => import('../views/pages/SubscriptionFinalize.vue'),
        meta: {
          middleware: [auth]
        },
      },

      {
        path: '/page/not-found',
        name: 'not-found',
        component: () => import('../views/pages/NotFound.vue'),
      },

      {
        path: '/page/profile',
        component: () => import('../views/pages/Profile.vue'),
        name: 'profile',
        meta: {
          middleware: [auth]
        },
      },

    ],
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export function createMiddlewarePipeline(context, middleware) {
  const nextMiddleware = middleware[0]
  const restMiddleware = middleware.slice(1)

  if (!nextMiddleware) {
    return context.next
  }

  return redirectRoute => {
    if (redirectRoute !== undefined) {
      context.next(redirectRoute)
    } else {
      const nextPipeline = createMiddlewarePipeline(context, restMiddleware)
      nextMiddleware({ ...context, next: nextPipeline })
    }
  }
}

router.beforeEach((to, from, next) => {
  const middleware = to.matched.reduce(
    (guards, matchedRoute) => {
      const routeGuards = matchedRoute.meta.middleware
        ? matchedRoute.meta.middleware.filter(someGuard => !guards.includes(someGuard))
        : []

      return routeGuards.length ? [...guards, ...routeGuards] : guards
    },
    [notFound]
  )

  createMiddlewarePipeline(
    {
      to,
      from,
      next,
      // store: {}
      store
    },
    middleware
  )()
})

export default router
