import { FETCH_PROFILE } from '@/store/modules/profile'

export default function auth(data) {
  const { next, store } = data
  console.log('store', data)
  const { isAuthenticated } = store.state.auth.model
  const { isProfileLoaded, amplitudeService } = store.getters
  
  console.log('isProfileLoaded', isProfileLoaded, 'isAuthenticated', isAuthenticated, 'isloading', !store.state.profile.profile.loading)
  if (isAuthenticated) {
    // if (!store.state.profile.profile.data && !store.state.profile.profile.loading) {
    //   console.log('start', {...store.state.profile.profile})
    //   store.dispatch(FETCH_PROFILE)
    // }
    return next()
  }
  
  return next({ name: 'sign-in' })
}
