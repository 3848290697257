<template>
  <header>

    <nav class="container d-lg-none">

      <router-link
        class="header__logo"
        :to="{name: 'home'}"
      >
        <img src="@/assets/images/logo.svg" alt="wipenote">
      </router-link>
      <div class="button_container toggle" :class="isBurgerOpen ? 'active-btn' : null" @click="toggleBurger">
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
      <div class="container-ham">
        <div id="overlay" class="overlay" :class="isBurgerOpen ? 'open' : null">
          <div id="navbar" class="overlay-menu">

            <div class="container">
              <!-- <div class="row"> -->
              <div class="footer__col footer__col_profile">
                <span v-if="profile">
                  <router-link
                    v-if="profileState.data.subscription.type == 'free'"
                    class="footer__unlimited_mobile"
                    @click.native="toggleBurger"
                    :to="{name: 'choose-subscription'}">
                    Buy unlimited
                  </router-link>
                  <div class="footer__login-wrapper">
                    <router-link
                      class="footer__link footer__link_id"
                      @click.native="toggleBurger"
                      :to="{name: 'profile'}">
                      #{{profile.accountNumber}}
                    </router-link>
                    <a class="footer__logout" @click.prevent="logout" href="#">
                      <img src="@/assets/images/logout.svg" alt="logout">
                    </a>
                  </div>
                </span>
                <p class="mb-0" v-else @click="toggleBurger">
                  <router-link class="footer__link footer__link_profile" :to="{name: 'sign-in'}" >Login</router-link>
                </p>
              </div>
              <div class="footer__col">
                <a class="footer__link" target="_blank" href="https://github.com/wipenote">Github</a>
                <p class="footer-link" @click="toggleBurger">
                  <router-link class="footer__link" :to="{name: 'about-us'}">About us</router-link>
                </p>
                <a class="footer__link" href="https://t.me/wipeworldsecrets_bot">Telegram</a>
              </div>
              <div class="footer__col-middle">
                <h3 class="footer__title">Donate (btc)</h3>
                <button class="footer__copy" data-clipboard-demo=""  data-clipboard-action="copy" data-clipboard-text="1Cpym7kXHnZA9h7h5prTPm6SM7paLQo9Ew">
                  <span class="footer__copy-icon"><img src="@/assets/images/copy.svg" alt="copy"></span>
                  1Cpym7kXHnZA9h7h5prTP<br/>m6SM7paLQo9Ew
                </button>
              </div>
              <div class="footer__col">
                <h3 class="footer__title-copyright">WipeNote</h3>
                <p class="footer__descrypt">Send notes that will self-destruct after being read</p>
                <hr class="footer__descrypt-hr">
                <p class="footer__copyright" href="#">© {{currentYear}} WipeNote</p>
                <a class="footer__link" href="mailto:info@wipenote.io">info@wipenote.io</a>
              </div>
              <!-- </div> -->
            </div>

          </div>
        </div>
      </div>
    </nav>

    <div class="container d-none d-lg-block">
      <div class="header__row row" :class="{header__row_auth : isAuthPage}">
        <!-- <img class="header__logo" src="/assets/images/logo.svg" alt="Logo" class="logo"> -->
        <div class="header__left">
          <router-link
            class="header__logo"
            :to="{name: 'home'}"
          >
  <!--          WipeNote-->
            <img src="@/assets/images/logo.svg" alt="wipenote">
          </router-link>
          <p class="header__description">Send notes that will self-destruct after being read</p>
        </div>
        <div class="header__right">

          <b-spinner v-if="isFetchingProfile" small variant="dark"></b-spinner>
          <span v-else-if="profile">
            <router-link
              v-if="subscription.isFree"
              class="header__unlimited"
              :to="{name: 'choose-subscription'}">
              Buy unlimited
            </router-link>

            <router-link :to="{name: 'profile'}">#{{profile.accountNumber}}</router-link>
            <a class="header__logout" @click.prevent="logout" href="#">
              <img src="@/assets/images/logout.svg" alt="logout">
            </a>
          </span>
          <router-link v-else :to="{name: 'sign-in'}">Login</router-link>
        </div>
      </div>
    </div>
  </header>


</template>

<script>
  import {FETCH_PROFILE, MODULE_NAME as PROFILE} from "@/store/modules/profile";
  import {LOGOUT_REQUEST, MODULE_NAME as AUTH} from "@/store/modules/auth";

  export default {
    name: 'Header',
    props: {
    },
    data() {
      return {
        bitcoinAddress: '1Cpym7kXHnZA9h7h5prTPm6SM7paLQo9Ew',
        isBurgerOpen: false,
        currentYear: new Date().getFullYear()
      }
    },
    computed: {
      profileState() {
        return this.$store.state[PROFILE].profile
      },
      isFetchingProfile() {
        return this.profileState.loading
      },
      profile() {
        return this.profileState.data
      },
      profileError() {
        return this.profileState.error
      },
      subscription() {
        return this.profile.subscription
      },
      isUnlimitedSubscription() {
        return this.profile.subscription && this.profile.subscription.type === 'unlimited'
      },
      isAuthPage() {
        return this.$route.name === 'sign-in' || this.$route.name === 'sign-up' || this.$route.name === 'forgot-password'
      }
    },
    created() {
      const authState = this.$store.state[AUTH]
      console.log('authState', authState)
      // if (authState.model.isAuthenticated && authState.profile && !authState.profile.loading) {
      // this.$store.dispatch(FETCH_PROFILE)
      // }
    },
    methods: {
      copyLink() {
        this.$clipboard(this.bitcoinAddress)
      },
      logout() {
        this.$store.dispatch(LOGOUT_REQUEST)
        this.toggleBurger()
      },
      toggleBurger() {
        this.isBurgerOpen = !this.isBurgerOpen
      }
    }
  }
</script>
