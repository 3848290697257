<template>
  <div class="main-container">
    <Header/>
    <router-view />
    <Footer/>
  </div>

</template>

<script>
  import Footer from './views/partials/Footer'
  import Header from './views/partials/Header'
  import {FETCH_PROFILE} from "@/store/modules/profile";
  import {MODULE_NAME as AUTH} from "@/store/modules/auth";
  import {nanoid} from 'nanoid';

export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    Header,
    Footer,
  },
  async mounted() {
    let profileUid

    if (this.$store.state[AUTH].model.isAuthenticated) {
      const data = await this.$store.dispatch(FETCH_PROFILE)
      profileUid = data.uid
    }

    const localStorageUserIdKey = 'wipenote_uid'

    const currentLocalStorageUserId = localStorage.getItem(localStorageUserIdKey)

    if (!currentLocalStorageUserId) {
      const userId = profileUid || nanoid()
      localStorage.setItem(localStorageUserIdKey, userId)
    }

    let uid = localStorage.getItem(localStorageUserIdKey)

    const sessionStartedKey = 'wipenote_session'

    if (!sessionStorage.getItem(sessionStartedKey)) {
      sessionStorage.setItem(sessionStartedKey, '1')
      this.$store.getters.apiService.sendEvent({
        e: 'start_session',
        uid
      })
    }
  }
}
</script>
