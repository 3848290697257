export const LOADING = 'loading'
export const LOADED = 'loaded'
export const SET_ERROR = 'setError'
export const SET_LOCALE = 'setLocale'
export const SET_MODEL = 'setModel'
export const UPDATE_MODEL = 'updateModel'
export const SET_LIST = 'setList'
export const ADD_LIST_ITEMS = 'addListItems'
export const UPDATE_LIST_ITEM = 'updateListItem'
export const SET_PARAMS = 'setParams'
export const UPDATE_PARAMS = 'updateParams'
export const FETCH_REQUEST = 'fetchRequest'
export const FETCH_REQUEST_SUCCESS = 'fetchRequestSuccess'
export const FETCH_REQUEST_ERROR = 'fetchRequestError'
export const SET_PROPERTY = 'setProperty'
export const SHOW_TOAST = 'showToast'
