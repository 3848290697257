import * as types from '@/store/mutations/types'

import AuthStorageProvider from '@/providers/AuthStorageProvider'
import {FETCH_REQUEST, FETCH_REQUEST_ERROR, FETCH_REQUEST_SUCCESS, SHOW_TOAST} from "@/store/mutations/types";
import {FETCH_PROFILE} from "@/store/modules/profile";

const accessToken = AuthStorageProvider.getAccessToken()
const refreshToken = AuthStorageProvider.getRefreshToken()

export const MODULE_NAME = 'auth'

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT = 'LOGOUT'
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST'
export const CONFIRM_EMAIL = `${MODULE_NAME}_confirmEmail`
export const SIGNUP_REQUEST = `${MODULE_NAME}_signupRequest`
export const FORGOT_PASSWORD = `${MODULE_NAME}_forgotPassword`
export const CHANGE_PASSWORD_BY_TOKEN = `${MODULE_NAME}_changePasswordByToken`
export const GOOGLE_SIGNIN = `${MODULE_NAME}_googleSignIn`

const getDefaultRequestState = (data = null) => ({
  loading: false,
  data,
  error: null
})

export default {
  state: {
    model: {
      isAuthenticated: !!accessToken
    },
    login: getDefaultRequestState(null),
    signup: getDefaultRequestState({}),
    confirmEmail: getDefaultRequestState({}),
    confirmAdditionalEmail: getDefaultRequestState({}),
    forgotPassword: getDefaultRequestState({}),
    changePasswordByToken: getDefaultRequestState({}),
    googleSignIn: getDefaultRequestState({}),
  },
  actions: {
    [SIGNIN_REQUEST]({ state, getters, commit, dispatch }, { email, password }) {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'login' })
  
      return getters.apiService
        .login({email, password})
        .then(({data}) => {
          commit(FETCH_REQUEST_SUCCESS, {module: MODULE_NAME, key: 'login', data})
  
          commit(types.SET_MODEL, {
            name: MODULE_NAME,
            model: {
              ...state.model,
              isAuthenticated: true
            }
          })
          
          this.$router.push({ path: '/' })
          dispatch(FETCH_PROFILE)
        })
        .catch(e => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'login',
            error: e.response && e.response.data && e.response.data.message || e.message
          })
          throw e
        })
        .finally(() => commit(types.LOADED, MODULE_NAME))
    },
    
    [GOOGLE_SIGNIN]({ state, getters, commit, dispatch }, { token }) {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'googleSignIn' })
  
      return getters.apiService
        .setGoogleAuthToken({token})
        .then((data) => {
          commit(FETCH_REQUEST_SUCCESS, {module: MODULE_NAME, key: 'googleSignIn', data})
  
          commit(types.SET_MODEL, {
            name: MODULE_NAME,
            model: {
              ...state.model,
              isAuthenticated: true
            }
          })
          
          this.$router.push({ path: '/' })
          dispatch(FETCH_PROFILE)
        })
        .catch(e => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'googleSignIn',
            error: e.response && e.response.data && e.response.data.message || e.message
          })
          throw e
        })
        .finally(() => commit(types.LOADED, MODULE_NAME))
    },
  
    async [LOGOUT_REQUEST]({ state, getters, commit, dispatch }) {
      commit(types.LOADING, MODULE_NAME)
    
      return getters.apiService
        .logout()
        .then(() => {
          dispatch(LOGOUT)
          commit(types.SET_MODEL, {
            name: MODULE_NAME,
            model: {
              ...state.model,
              isAuthenticated: false
            }
          })
        
          this.$router.push({ name: 'home' })
        })
        .catch(e => {
          console.error(e)
          commit(types.SET_MODEL, {
            name: MODULE_NAME,
            model: {
              ...state.model,
              logoutError: e.response && e.response.data
            }
          })
        })
        .finally(() => commit(types.LOADED, MODULE_NAME))
    },
  
    [REFRESH_TOKEN_REQUEST]: async ({ getters, commit }) => {
      commit(types.LOADING, MODULE_NAME)
    
      return getters.apiService.refreshToken().finally(() => commit(types.LOADED, MODULE_NAME))
    },
  
    [SIGNUP_REQUEST]: async ({ commit, getters }, { email, password }) => {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'signup' })
  
      return getters.apiService
        .signup({email, password})
        .then(({data}) => {
          commit(FETCH_REQUEST_SUCCESS, {
            module: MODULE_NAME,
            key: 'signup',
            data: {
              ...data,
              email,
            }
          })
  
        })
        .catch(e => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'signup',
            error: (e.response && e.response.data) || 'Error fetching profile'
          })
          throw e
        })
    },
    [CONFIRM_EMAIL]({ commit, getters, dispatch, state }, { token, message }) {
      commit(types.FETCH_REQUEST, { module: MODULE_NAME, key: 'confirmEmail' })
    
      return getters.apiService
        .confirmAccount(token)
        .then(({ data }) => {
          commit(types.FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'confirmEmail', data })
  
          commit(types.SET_MODEL, {
            name: MODULE_NAME,
            model: {
              ...state.model,
              isAuthenticated: true
            }
          })
  
          this.$router.push({ path: '/' })
          dispatch(FETCH_PROFILE)
  
          commit(types.SHOW_TOAST, {
            variant: 'success',
            // message: 'You are successfully logged in',
            message,
            id: 'welcome-message',
            toastClass: 'welcome-message',
            autoHideDelay: 5000,
          })
          return data
        })
        .catch(error => commit(types.FETCH_REQUEST_ERROR, { module: MODULE_NAME, key: 'confirmEmail', error }))
    },
  
    [FORGOT_PASSWORD]: ({ commit, getters }, { email }) => {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'forgotPassword' })
    
      return getters.apiService
        .forgotPassword({ email })
        .then(data => {
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'forgotPassword', data })
        })
        .catch(error => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'forgotPassword',
            error
          })
        
          throw error
        })
    },
  
    [CHANGE_PASSWORD_BY_TOKEN]: ({ commit, getters }, { token, password }) => {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'changePasswordByToken' })
    
      return getters.apiService
        .forgotPasswordConfirm({ token, password })
        .then(data => {
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'changePasswordByToken', data })
          commit(SHOW_TOAST, { variant: 'success', message: 'Password updated successfully' })
        })
        .catch(error => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'changePasswordByToken',
            error
          })
        
          throw error
        })
    },
  }
}
