import {
  SET_MODEL,
  FETCH_REQUEST,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_ERROR,
  SHOW_TOAST,
  SET_PROPERTY
} from '../mutations/types'
import { LOGOUT } from './auth'

export const MODULE_NAME = 'profile'
export const CLEAR_PROFILE = `${MODULE_NAME}_clearProfile`
export const FETCH_PROFILE = `${MODULE_NAME}_fetchProfile`
export const UPDATE_PROFILE = `${MODULE_NAME}_updateProfile`
export const CHANGE_PASSWORD = `${MODULE_NAME}_changePassword`
export const CANCEL_SUBSCRIPTION = `${MODULE_NAME}_cancelSubscription`

const getDefaultRequestState = (data = null) => ({
  loading: false,
  data,
  error: null
})

export default {
  state: {
    loading: false,
    model: {
      empty: true
    },
    profile: getDefaultRequestState(null),
    updateProfile: getDefaultRequestState({}),
    changePassword: getDefaultRequestState({}),
    changePasswordByToken: getDefaultRequestState({}),
    forgotPassword: getDefaultRequestState({}),
    cancelSubscription: getDefaultRequestState({}),
  },
  getters: {
    isProfileLoaded: state => state.profile.data
  },
  actions: {
    [FETCH_PROFILE]: ({ commit, getters }) => {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'profile' })

      return getters.apiService
        .getProfile()
        .then(({ data }) => {
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'profile', data })
          return data
        })
        .catch(() => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'profile',
            error: 'Error fetching profile'
          })
        })
    },
    [UPDATE_PROFILE]: ({ commit, getters }, payload) => {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'updateProfile' })

      return getters.apiService
        .updateProfile(payload)
        .then(data => {
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'updateProfile', data })
          commit(SHOW_TOAST, { variant: 'success', message: 'Profile updated successfully' })
        })
        .catch(() => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'updateProfile',
            error: 'Error updating user profile'
          })
        })
    },
    [CHANGE_PASSWORD]: ({ commit, getters }, { oldPassword, newPassword }) => {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'changePassword' })

      return getters.apiService
        .changeProfilePassword({ oldPassword, newPassword })
        .then(data => {
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'changePassword', data })
          commit(SHOW_TOAST, { variant: 'success', message: 'Profile updated successfully' })
        })
        .catch((e) => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'changePassword',
            error: e.response && e.response.data && e.response.data.message || 'Error changing password'
          })
        })
    },
    [CLEAR_PROFILE]: ({ commit }) => {
      commit(SET_MODEL, { name: MODULE_NAME, model: { empty: true } })
      commit(SET_PROPERTY, {
        module: MODULE_NAME,
        property: 'profile',
        value: getDefaultRequestState(null)
      })
    },
    [CANCEL_SUBSCRIPTION]({ commit, getters, state }, { id }) {
      commit(FETCH_REQUEST, { module: MODULE_NAME, key: 'cancelSubscription' })
    
      return getters.apiService
        .cancelSubscription(id)
        .then(({ data }) => {
          console.log('state.profile', state.profile, data)
          commit(SET_PROPERTY, {
            module: MODULE_NAME,
            property: 'profile',
            value: {
              ...state.profile,
              data: {
                ...state.profile.data,
                subscription: data.profileSubscription,
              }
            }
          })
          console.log('new', data.profileSubscription)
          commit(FETCH_REQUEST_SUCCESS, { module: MODULE_NAME, key: 'cancelSubscription', data })
          
          return data
        })
        .catch((e) => {
          commit(FETCH_REQUEST_ERROR, {
            module: MODULE_NAME,
            key: 'cancelSubscription',
            error: e.response && e.response.data && e.response.data.message || e.message || 'Sending payment status'
          })
        })
    },
    [LOGOUT]: ({ dispatch }) => {
      dispatch(CLEAR_PROFILE)
    },
  }
}
