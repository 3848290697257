import Vue from 'vue'
import { Plugin } from 'vue-fragment';
import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue'
import Clipboard from 'v-clipboard'
import Vuelidate from 'vuelidate'
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';

import App from './App.vue'
import router from './router'
import store from './store'

import Interceptors from './helpers/interceptors'

import '@fortawesome/fontawesome-free/js/all.js'

import './assets/styles/index.scss'
import 'file-icon-vectors/dist/file-icon-square-o.min.css'

Raven
  .config('https://537569b5ed3b42ca968eaad482fcf1f2@sentry.kryptonhub.com/11')
  .addPlugin(RavenVue, Vue)
  .install();

Interceptors.init(store)

Vue.use(Plugin)
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(IconsPlugin)
Vue.use(Clipboard)
Vue.use(Vuelidate)

Vue.config.productionTip = false

store.$router = router

// console.log({...window.location})

const pathname = window.location.pathname
const isNoteUrl =
  //new note url (ex. /kvDv3g#dGE)
  pathname !== '/' && !pathname.includes('/note-created') && !pathname.startsWith('/page/') ||
  //old note url (ex. /note/kvDv3g#dGE)
  pathname.startsWith('/note/') && !pathname.startsWith('/note/note-created') && !pathname.startsWith('/page/')

if (isNoteUrl && localStorage) {
  localStorage.removeItem('wipenote-get-note_id')
  localStorage.removeItem('wipenote-get-note_pwd')

  let noteId = pathname.replace(window.location.hash, '').replace('/note/', '')
  if (noteId.startsWith("/")) {
    noteId = noteId.slice(1)
  }
  let password = window.location.hash.replace('#', '')

  // console.log('note and not note-created', noteId, password)
  // console.log('noteId', noteId, 'password', password)
  // window.history.replaceState({}, 'home', '/')
  localStorage.setItem('wipenote-get-note_id', noteId)
  localStorage.setItem('wipenote-get-note_pwd', password)
  window.location.href = '/page/note-hidden'
  // const path = window.location.pathname
  // const password = window.location.hash
}

const vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

store.$app = vue
