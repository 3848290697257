<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="footer__col col-md-3">
          <a class="footer__link" href="https://github.com/wipenote" target="_blank">Github</a>
          <a class="footer__link" href="/about-us">About us</a>
          <a class="footer__link" href="https://t.me/wipeworldsecrets_bot">Telegram</a>
        </div>
        <div class="footer__col-middle col-md-6">
          <h3 class="footer__title">Donate (btc)</h3>
          <button class="footer__copy" data-clipboard-demo=""  data-clipboard-action="copy" data-clipboard-text="1Cpym7kXHnZA9h7h5prTPm6SM7paLQo9Ew">
          <span class="footer__copy-icon">
            <img src="../../assets/images/copy.svg" alt="copy">
          </span>
            1Cpym7kXHnZA9h7h5prTP<br/>m6SM7paLQo9Ew
          </button>

        </div>
        <div class="footer__col footer__col_last col-md-3">
          <h3 class="footer__title-copyright">
            <img src="../../assets/images/logo_footer.svg" alt="">
          </h3>
          <p class="footer__copyright">© {{currentYear}} WipeNote</p>
          <a class="footer__link" href="mailto:info@wipenote.io">
            info@wipenote.io
          </a>
        </div>
      </div>
    </div>
  </footer>


</template>

<script>
  export default {
    name: 'Footer',
    props: {
    },
    data() {
      return {
        currentYear: new Date().getFullYear()
      }
    }
  }
</script>
